import { FC, Fragment, useCallback, useEffect, useState } from "react";

import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Flex,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Typography,
  message,
} from "antd";
import fr from "antd/es/date-picker/locale/fr_FR";
import { isEmpty, omit, pickBy } from "lodash";
import { useLoaderData } from "react-router-dom";
import { Link } from "react-router-dom";

import DuplicateTransactions from "./actions/DuplicateTransactions";
import LinkToFao from "./actions/LinkToFao";
import RefreshAddress from "./actions/RefreshAddress";
import ValidateUpdates from "./actions/ValidateUpdates";
import {
  Field,
  FieldOption,
  GroupedFields,
  calculatedFields,
} from "./services/collectionFields";
import {
  createItem,
  ItemChanges,
  saveItemChanges,
} from "./services/collectionItems";

const { TextArea } = Input;

interface EditCollectionProps {
  editItem: any;
  mode: "edit" | "create";
  onCloseEdit: () => void;
  onEditNext?: () => void;
  onEditPrevious?: () => void;
}

const EditCollection: FC<EditCollectionProps> = ({
  editItem,
  mode,
  onCloseEdit,
  onEditNext,
  onEditPrevious,
}) => {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [changedValues, setChangedValues] = useState<ItemChanges>({});
  const { collectionId, groupedFields } = useLoaderData() as {
    collectionId: string;
    groupedFields: GroupedFields;
  };

  const isEditMode = mode === "edit";

  useEffect(() => {
    form.setFieldsValue(editItem);
  }, [editItem, form]);

  const handleValuesChange = useCallback(
    async (updatedValues: any) => {
      const actuallyChangedValues = pickBy(updatedValues, (_val, key) => {
        return editItem[key] !== updatedValues[key];
      });

      if (!isEmpty(actuallyChangedValues)) {
        const calculationResult = await calculatedFields[collectionId](
          Object.keys(actuallyChangedValues)[0],
          { ...editItem, ...changedValues, ...actuallyChangedValues }
        );
        form.setFieldsValue(calculationResult);

        setChangedValues((values) => ({
          ...values,
          ...actuallyChangedValues,
          ...calculationResult,
        }));
      } else {
        setChangedValues((values) =>
          omit({ ...values }, Object.keys(updatedValues)[0])
        );
      }
    },
    [editItem, changedValues, setChangedValues, form]
  );

  const handleClose = () => {
    setChangedValues({});
    onCloseEdit();
  };

  const handleSaveItemChanges = async () => {
    if (!isEmpty(changedValues)) {
      try {
        isEditMode &&
          (await saveItemChanges(editItem.id, collectionId, changedValues));

        !isEditMode && (await createItem(collectionId, changedValues));

        messageApi.open({
          type: "success",
          content: isEditMode
            ? "La mise à jour a été effectuée avec succès."
            : "La transaction à jour a été créée avec succès.",
        });

        setChangedValues({});
      } catch (error) {
        console.error(error);
        messageApi.open({
          type: "error",
          content: isEditMode
            ? "Une erreur est survenue lors de la mise à jour."
            : "Une erreur est survenue lors de la création.",
        });
      }
    }
  };

  return (
    <>
      {contextHolder}
      <Drawer
        width="85%"
        title={
          isEditMode ? (
            <Flex
              style={{ paddingLeft: 12 }}
              justify="space-between"
              align="center"
            >
              {editItem && (
                <Typography.Text
                  strong
                  style={{ fontSize: 16 }}
                >{`${editItem.NoParcelle} - ${editItem.Requete} - ${editItem.Adresse}, ${editItem.NPA} ${editItem.CommuneAdresse}`}</Typography.Text>
              )}
              <Flex gap={16}>
                <Button
                  icon={<LeftOutlined />}
                  iconPosition="start"
                  disabled={!onEditPrevious}
                  onClick={() => {
                    setChangedValues({});
                    onEditPrevious && onEditPrevious();
                  }}
                >
                  Previous
                </Button>
                <Button
                  icon={<RightOutlined />}
                  iconPosition="end"
                  disabled={!onEditNext}
                  onClick={() => {
                    setChangedValues({});
                    onEditNext && onEditNext();
                  }}
                >
                  Next
                </Button>
              </Flex>
            </Flex>
          ) : (
            "Nouvelle transaction"
          )
        }
        open={!!editItem}
        onClose={handleClose}
        footer={
          <Flex justify="space-between">
            {editItem && (
              <Flex gap="middle" align="center">
                {collectionId === "transactions_updates" && (
                  <ValidateUpdates ids={[editItem.id]} />
                )}

                {["transactions_updates", "transactions_fao"].includes(
                  collectionId
                ) && isEditMode ? (
                  <>
                    <RefreshAddress ids={[editItem.id]} />
                    <DuplicateTransactions ids={[editItem.id]} />
                  </>
                ) : (
                  <div />
                )}

                {collectionId === "transactions_updates" && (
                  <LinkToFao
                    requete={editItem.Requete.split("/").slice(0, 2).join("/")}
                  />
                )}
              </Flex>
            )}
            <Flex gap="middle">
              {["transactions_updates", "transactions_fao"].includes(
                collectionId
              ) && (
                <>
                  <Button onClick={handleClose}>Annuler</Button>
                  <Button
                    onClick={handleSaveItemChanges}
                    type="primary"
                    disabled={isEmpty(changedValues)}
                  >
                    {isEditMode ? "Sauvegarder" : "Créer"}
                  </Button>
                </>
              )}
            </Flex>
          </Flex>
        }
      >
        {editItem && (
          <Form
            size="middle"
            form={form}
            layout="vertical"
            autoComplete="off"
            initialValues={editItem}
            onValuesChange={handleValuesChange}
          >
            {Object.keys(groupedFields)
              .sort((g1, g2) => (g1 > g2 ? 1 : -1))
              .map((group: string) => {
                return (
                  <Fragment key={group}>
                    {group && <Divider style={{ margin: "8px 0" }}></Divider>}

                    <Row gutter={24}>
                      {groupedFields[group]
                        .sort((f1, f2) => f1.sort - f2.sort)
                        .map((field: Field) => {
                          const { name, label, type } = field;
                          return (
                            <Col key={name} sm={12} md={8} lg={8} xl={6}>
                              <Form.Item
                                key={name}
                                name={name}
                                label={label}
                                style={{
                                  marginBottom: 16,
                                }}
                                validateStatus={
                                  changedValues[name] ? "warning" : ""
                                }
                                valuePropName={
                                  type === "boolean" ? "checked" : "value"
                                }
                              >
                                {getEditComponent(field, editItem)}
                              </Form.Item>
                            </Col>
                          );
                        })}
                    </Row>
                  </Fragment>
                );
              })}
          </Form>
        )}
      </Drawer>
    </>
  );
};

export default EditCollection;

const getEditComponent = (field: Field, editItem: any) => {
  const { ui, type, name, options, configuration } = field;
  const isFieldHighlighted = configuration?.highlighted;
  const highlightedBackgroundColor = isFieldHighlighted ? "yellow" : "inherit";

  console.log(field);
  console.log(editItem[name]);

  switch (ui) {
    case "datetime":
      return (
        <DatePicker
          style={{ width: "100%", backgroundColor: highlightedBackgroundColor }}
          locale={fr}
        />
      );

    case "input-multiline":
      return (
        <TextArea
          style={{ backgroundColor: highlightedBackgroundColor }}
          rows={6}
        />
      );

    case "select-dropdown":
      return (
        <Select
          className={isFieldHighlighted ? "highlighted" : ""}
          style={{ width: "100%" }}
        >
          {(options || []).map((option: FieldOption) => (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      );

    case "input":
      if (type === "integer") {
        return (
          <InputNumber<number>
            style={{
              width: "100%",
              backgroundColor: highlightedBackgroundColor,
            }}
            step={1}
            type="number"
          />
        );
      }

      if (type === "float") {
        return (
          <InputNumber<number>
            style={{
              width: "100%",
              backgroundColor: highlightedBackgroundColor,
            }}
            step={0.01}
            type="number"
          />
        );
      }

      return (
        <Input
          style={{ width: "100%", backgroundColor: highlightedBackgroundColor }}
        />
      );

    case "map":
      if (editItem[name]) {
        const { coordinates } = editItem[name];
        const lat = coordinates[1];
        const long = coordinates[0];
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Link
              target="_blank"
              to={`https://www.google.ch/maps/place/${lat},${long}`}
            >
              <Typography.Link>{`${lat} / ${long}`}</Typography.Link>
            </Link>
            <Link
              target="_blank"
              to={`https://map.sitg.ge.ch/app/?locparcelle=${editItem?.NoCommune}:${editItem?.NoParcelle}&mapresources=AMENAGEMENT`}
            >
              <Typography.Link>Voir sur SITG</Typography.Link>
            </Link>
            <Link
              target="_blank"
              to={`https://ge.ch/terextraitfoncier/rapport.aspx?commune=${editItem?.NoCommune}&parcelle=${editItem?.NoParcelle}`}
            >
              <Typography.Link>Extrait foncier</Typography.Link>
            </Link>
          </div>
        );
      }
      return <div></div>;

    case "boolean":
      return (
        <Checkbox
          className={isFieldHighlighted && !editItem[name] ? "highlighted" : ""}
        />
      );

    default:
      return (
        <Input
          style={{ width: "100%", backgroundColor: highlightedBackgroundColor }}
        />
      );
  }
};
