import { FC, useState } from "react";
import { Button, message, Modal, Table } from "antd";
import axiosInstance from "../axiosInstance";
import { getCollectionFields } from "../services/collectionFields";
import { transformToApiQueryParams } from "../hooks/useQueryParamsToState";

interface YearData {
  avg_prix: number;
  avg_svb_verif: number;
  max_prix: number;
  max_svb_verif: number;
  median_prix: number;
  median_svb_verif: number;
  median_prix_per_m2: number;
  min_prix_per_m2: number;
  max_prix_per_m2: number;
  min_prix: number;
  min_svb_verif: number;
  nb_trans: number;
  volume: number;
  avg_prix_m2: number;
}

interface StatsData {
  avg_prix: number;
  avg_svb_verif: number;
  max_prix: number;
  max_svb_verif: number;
  median_prix: number;
  median_svb_verif: number;
  median_prix_per_m2: number;
  min_prix_per_m2: number;
  max_prix_per_m2: number;
  min_prix: number;
  min_svb_verif: number;
  nb_trans: number;
  volume: number;
  avg_prix_m2: number;
  years_data: Record<string, YearData>;
}

const Statistiques: FC<{ filters: Record<string, string[]> }> = ({
  filters,
}) => {
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [statsData, setStatsData] = useState<StatsData | null>(null);
  const [messageApi, contextHolder] = message.useMessage();

  const handleFetchStats = async () => {
    setLoading(true);
    try {
      const collectionId = "transactions_fao";

      const fields = await getCollectionFields(collectionId);
      const apiParams = transformToApiQueryParams(
        fields,
        new URL(window.location.href).searchParams
      );

      const response = await axiosInstance.post(
        `https://melcarnebackend.digipulse.ch/get_stats_admin_list`,
        { filters: JSON.parse(apiParams.get("filter")!) }
      );

      setStatsData(response.data);
      setModalVisible(true); // Open modal with stats

      messageApi.open({
        type: "success",
        content: "Les statistiques ont été récupérées avec succès.",
      });
    } catch (error) {
      console.log(error);
      messageApi.open({
        type: "error",
        content: "Une erreur est survenue lors de la demande de statistiques.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {contextHolder}

      {/* Fetch Stats Button */}
      <Button
        style={{ backgroundColor: "#fee08b" }}
        onClick={handleFetchStats}
        loading={loading}
      >
        {`Statistiques sur cette recherche`}
      </Button>

      {/* Modal for Stats */}
      <Modal
        title="Statistiques de la recherche"
        width="80vw"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setModalVisible(false)}>
            Fermer
          </Button>,
        ]}
      >
        {statsData ? (
          <div>
            <p>
              <strong>Nombre de transactions :</strong>{" "}
              {statsData.nb_trans.toLocaleString()}
            </p>
            <p>
              <strong>Volume total :</strong>{" "}
              {Math.floor(statsData.volume).toLocaleString()} CHF
            </p>
            <hr />
            <p>
              <strong>Prix minimum :</strong>{" "}
              {Math.floor(statsData.min_prix).toLocaleString()} CHF
            </p>
            <p>
              <strong>Prix moyen :</strong>{" "}
              {Math.floor(statsData.avg_prix).toLocaleString()} CHF
            </p>
            <p>
              <strong>Prix médian :</strong>{" "}
              {Math.floor(statsData.median_prix).toLocaleString()} CHF
            </p>
            <p>
              <strong>Prix maximum :</strong>{" "}
              {Math.floor(statsData.max_prix).toLocaleString()} CHF
            </p>
            <hr />
            <p>
              <strong>SVB Vérif minimum :</strong>{" "}
              {Math.floor(statsData.min_svb_verif).toLocaleString()} m2
            </p>
            <p>
              <strong>SVB Vérif moyen :</strong>{" "}
              {Math.floor(statsData.avg_svb_verif).toLocaleString()} m2
            </p>
            <p>
              <strong>SVB Vérif médian :</strong>{" "}
              {Math.floor(statsData.median_svb_verif).toLocaleString()} m2
            </p>
            <p>
              <strong>SVB Vérif maximum :</strong>{" "}
              {Math.floor(statsData.max_svb_verif).toLocaleString()} m2
            </p>
            <hr />
            <p>
              <strong>Prix/m2 moyen :</strong>{" "}
              {Math.floor(statsData.avg_prix_m2).toLocaleString()} CHF/m2
            </p>
            <p>
              <strong>Prix/m2 median :</strong>{" "}
              {Math.floor(statsData.median_prix_per_m2).toLocaleString()} CHF/m2
            </p>
            <p>
              <strong>Prix/m2 min :</strong>{" "}
              {Math.floor(statsData.min_prix_per_m2).toLocaleString()} CHF/m2
            </p>
            <p>
              <strong>Prix/m2 max :</strong>{" "}
              {Math.floor(statsData.max_prix_per_m2).toLocaleString()} CHF/m2
            </p>
          </div>
        ) : (
          <p>Chargement des statistiques...</p>
        )}

        {/* Yearly Data */}
        {statsData?.years_data && (
          <div style={{ marginTop: 16 }}>
            <h1 style={{ fontWeight: "bold" }}>Données par année</h1>
            <Table
              dataSource={Object.entries(statsData.years_data).map(
                ([year, data]) => ({
                  key: year,
                  year,
                  ...data,
                })
              )}
              columns={[
                {
                  title: "Année",
                  dataIndex: "year",
                  key: "year",
                  fixed: "left",
                },
                {
                  title: "Nombre de transactions",
                  dataIndex: "nb_trans",
                  key: "nb_trans",
                },
                {
                  title: "Volume",
                  dataIndex: "volume",
                  key: "volume",
                  render: (value) =>
                    Math.floor(value).toLocaleString() + " CHF",
                },

                {
                  title: "Prix min",
                  dataIndex: "min_prix",
                  key: "min_prix",
                  render: (value) =>
                    Math.floor(value).toLocaleString() + " CHF",
                },
                {
                  title: "Prix moyen",
                  dataIndex: "avg_prix",
                  key: "avg_prix",
                  render: (value) =>
                    Math.floor(value).toLocaleString() + " CHF",
                },
                {
                  title: "Prix médian",
                  dataIndex: "median_prix",
                  key: "median_prix",
                  render: (value) =>
                    Math.floor(value).toLocaleString() + " CHF",
                },
                {
                  title: "Prix max",
                  dataIndex: "max_prix",
                  key: "max_prix",
                  render: (value) =>
                    Math.floor(value).toLocaleString() + " CHF",
                },

                {
                  title: "SVB v. min",
                  dataIndex: "min_svb_verif",
                  key: "min_svb_verif",
                  render: (value) => Math.floor(value).toLocaleString() + " m2",
                },
                {
                  title: "SVB v. moyen",
                  dataIndex: "avg_svb_verif",
                  key: "avg_svb_verif",
                  render: (value) => Math.floor(value).toLocaleString() + " m2",
                },
                {
                  title: "SVB v. médian",
                  dataIndex: "median_svb_verif",
                  key: "median_svb_verif",
                  render: (value) => Math.floor(value).toLocaleString() + " m2",
                },
                {
                  title: "SVB v. max",
                  dataIndex: "max_svb_verif",
                  key: "max_svb_verif",
                  render: (value) => Math.floor(value).toLocaleString() + " m2",
                },

                {
                  title: "Prix/m2 moyen",
                  dataIndex: "avg_prix_m2",
                  key: "avg_prix_m2",
                  render: (value) =>
                    Math.floor(value).toLocaleString() + " CHF/m2",
                },
                {
                  title: "Prix/m2 median",
                  dataIndex: "median_prix_per_m2",
                  key: "median_prix_per_m2",
                  render: (value) =>
                    Math.floor(value).toLocaleString() + " CHF/m2",
                },
                {
                  title: "Prix/m2 min",
                  dataIndex: "min_prix_per_m2",
                  key: "min_prix_per_m2",
                  render: (value) =>
                    Math.floor(value).toLocaleString() + " CHF/m2",
                },
                {
                  title: "Prix/m2 max",
                  dataIndex: "max_prix_per_m2",
                  key: "max_prix_per_m2",
                  render: (value) =>
                    Math.floor(value).toLocaleString() + " CHF/m2",
                },
              ]}
              pagination={false}
              scroll={{ x: "max-content" }} // Enables horizontal scroll
            />
          </div>
        )}
      </Modal>
    </>
  );
};

export default Statistiques;
