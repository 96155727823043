import dayjs from "dayjs";

import { Field } from "./collectionFields";

import axiosInstance from "../axiosInstance";

export type Item = Record<string, any>;

export interface CollectionPayload {
  data: {
    items: Item[];
    nbItems: number;
    total: number;
  };
  fields: Field[];
  limit: number;
  page: number;
  loading: boolean;
  collectionId: string;
  admin: boolean;
}

export const getCollectionItems = async (
  collectionId: string,
  fields: Field[],
  searchParams: string
) => {
  if (!searchParams.includes("sort=")) {
    searchParams = searchParams + "&sort=-Date_Transaction";
  }
  const response = await axiosInstance.get(
    `https://melcarnedirectus2.digipulse.ch/items/${collectionId}?meta=*&fields[]=id,${fields
      .map((field) => field.name)
      .join(",")}&${searchParams}`
  );
  const data = response.data.data;

  const transformedRow = data.map((row: Item) => {
    let newRow = { ...row, key: row.id };
    fields.forEach((field) => {
      if (field.type === "date" && row[field.name]) {
        newRow = {
          ...newRow,
          [field.name]: dayjs(
            new Intl.DateTimeFormat("fr-CA").format(
              new Date(
                row[field.name].indexOf("T") > -1
                  ? ""
                  : row[field.name] + "T00:00:00.000Z"
              )
            ),
            "YYYY-MM-DD",
            true
          ),
        };
      } else {
        newRow = { ...newRow, [field.name]: row[field.name] };
      }
    });

    return newRow;
  });

  return {
    items: transformedRow,
    nbItems: response.data.meta.filter_count,
    total: response.data.meta.total_count,
  };
};

export type ItemChanges = Record<string, any>;

export const saveItemChanges = async (
  id: number,
  collectionId: string,
  changes: ItemChanges
) => {
  await axiosInstance.patch(
    `https://melcarnedirectus2.digipulse.ch/items/${collectionId}/${id}`,
    changes
  );
};

export const createItem = async (
  collectionId: string,
  changes: ItemChanges
) => {
  await axiosInstance.post(
    `https://melcarnedirectus2.digipulse.ch/items/${collectionId}`,
    changes
  );
};

export const duplicateItems = async (ids: string[]) => {
  await Promise.all(
    ids.map((id) =>
      axiosInstance.post(
        `https://melcarnebackend.digipulse.ch/duplicate_transaction`,
        { body: { key: id } }
      )
    )
  );
};

export const refreshAdresses = async (ids: string[]) => {
  await Promise.all(
    ids.map((id) =>
      axiosInstance.post(
        `https://melcarnebackend.digipulse.ch/refresh_address`,
        { body: { key: id } }
      )
    )
  );
};
