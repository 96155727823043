import { FC, useEffect, useState } from "react";
import { Button, Checkbox, Divider, Flex, InputNumber, Slider } from "antd";
import { FilterDropdownProps } from "antd/es/table/interface";

import axiosInstance from "../axiosInstance";

interface YearFilterProps {
  field: string;
  collectionId: string;
  dropdownProps: FilterDropdownProps;
  searchSuggestion?: string;
}

const MIN_YEAR = 2005;
const MAX_YEAR = new Date().getFullYear();

const YearFilter: FC<YearFilterProps> = ({
  field,
  collectionId,
  dropdownProps,
  searchSuggestion
}) => {
  const [filterInterval, setFilterInterval] = useState<
    [min: number, max: number]
  >([0, 0]);
  const [selectedNumberRange, setSelectedNumberRange] =
    useState<[start: number, end: number]>(filterInterval);
  const [onlyNull, setOnlyNull] = useState(false);

  useEffect(() => {
    const getFilterInterval = async () => {
      setFilterInterval([MIN_YEAR, MAX_YEAR]);
    };

    getFilterInterval();
  }, [collectionId, field]);

  useEffect(() => {
    if (dropdownProps.selectedKeys.length > 0) {
      setSelectedNumberRange([
        dropdownProps.selectedKeys[0] as number,
        dropdownProps.selectedKeys[1] as number,
      ]);
    } else {
      setSelectedNumberRange(filterInterval);
    }
  }, [dropdownProps.selectedKeys, filterInterval]);

  const handleCheckboxChange = (checked: boolean) => {
    setOnlyNull(checked);
    if (checked) {
      // Set selected keys to null when checkbox is ticked
      dropdownProps.setSelectedKeys(["", ""]);
    } else {
      // Reset selected keys when checkbox is unticked
      dropdownProps.setSelectedKeys(filterInterval);
    }
  };

  console.log(searchSuggestion); 

  return (
    <Flex vertical gap={8} style={{ padding: 8, width: 300 }}>
      {/* Checkbox to filter null values */}
      <Checkbox
        checked={onlyNull}
        onChange={(e) => handleCheckboxChange(e.target.checked)}
      >
        Valeurs nulles
      </Checkbox>

      {/* Slider and Input Numbers */}
      <Flex vertical style={{ paddingLeft: 16, paddingRight: 16, opacity: onlyNull ? 0.5 : 1 }}>
        <Slider
          min={filterInterval[0]}
          max={filterInterval[1]}
          range
          value={selectedNumberRange}
          onChange={(value) => dropdownProps.setSelectedKeys(value)}
          step={1}
          marks={{
            [filterInterval[0]]: filterInterval[0],
            [filterInterval[1]]: filterInterval[1],
          }}
          disabled={onlyNull} // Disable when checkbox is ticked
        />
        <Flex justify="space-between" style={{ width: "100%" }}>
          <InputNumber
            style={{ width: 100 }}
            value={dropdownProps.selectedKeys[0] as number}
            size="small"
            onChange={(value) => {
                if (searchSuggestion === "exact") {
                  dropdownProps.setSelectedKeys([value as number, value as number]);
                } else if (searchSuggestion === "gte") {
                  dropdownProps.setSelectedKeys([value as number, MAX_YEAR]);
                } else {
                  dropdownProps.setSelectedKeys([
                    value as number,
                    dropdownProps.selectedKeys[1],
                  ]);
                }
            }}
            step={1}
            type="number"
            disabled={onlyNull} // Disable when checkbox is ticked
          />
          <InputNumber
            style={{ width: 100 }}
            value={dropdownProps.selectedKeys[1] as number}
            size="small"
            onChange={(value) =>
              dropdownProps.setSelectedKeys([
                dropdownProps.selectedKeys[0],
                value as number,
              ])
            }
            step={1}
            type="number"
            disabled={onlyNull} // Disable when checkbox is ticked
          />
        </Flex>
      </Flex>

      <Divider style={{ marginTop: 0, marginBottom: 0 }} />
      <Flex justify="space-between">
        <Button
          onClick={() => dropdownProps.setSelectedKeys([])}
          type="link"
          size="small"
          disabled={dropdownProps.selectedKeys.length === 0}
        >
          Réinitialiser
        </Button>
        <Button
          onClick={() => dropdownProps.confirm()}
          type="primary"
          size="small"
        >
          OK
        </Button>
      </Flex>
    </Flex>
  );
};

export default YearFilter;
