import { FC, useState } from "react";

import { Button } from "antd";

import EditCollection from "../EditCollection";

const NewTransaction: FC = () => {
  const [transaction, setTransaction] = useState<any>(undefined);

  return (
    <>
      <Button type="primary" onClick={() => setTransaction({})}>
        Créer transaction
      </Button>

      <EditCollection
        editItem={transaction}
        mode="create"
        onCloseEdit={() => setTransaction(undefined)}
      />
    </>
  );
};

export default NewTransaction;
